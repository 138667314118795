<template>
  <div class="auth-wrapper auth-v2">
    <b-row :style="loginStyle" class="auth-inner m-0 justify-content-center">
      <b-col lg="10" class="pt-5">
          <InitialHeader  :onlyLogo="true" />
      </b-col>
      <b-col
          sm="12"
          lg="12"
          class="d-flex align-items-center mb-5"
      >
        <b-col
            sm="12"
            md="10"
            lg="4"
            class="px-lg-3 mx-auto auth-bg py-5 rounded"
        >
          <h2
              class="mb-1 auth-title"
          >
            Redefina sua nova senha
          </h2>

          <b-card-text class="mb-2">
            <feather-icon class="icons-color" icon="CheckCircleIcon" />
            Sua senha deve conter entre 8 e 16 caracteres.
          </b-card-text>

          <b-card-text class="mb-2">
            <feather-icon class="icons-color" icon="CheckCircleIcon" />
            Utilize ao menos duas das seguintes opções:

            <ul>
              <li>Letra Maiúscula</li>
              <li>Letra Minúscula</li>
              <li>Número</li>
              <li>Caractere Especial</li>
            </ul>
          </b-card-text>

          <b-card-text class="mb-2">
            <feather-icon class="icons-color" icon="SlashIcon" />
            Não utilize caracteres com acento.
          </b-card-text>

          <b-alert
              v-if="alert.show"
              show
              fade
              class="mb-2"
              :variant="alert.variant"
          >
            <div class="custom-alert">
              <span>
                <BIconCheckCircle v-if="alert.variant === 'success'" />
                <BIconExclamationCircle v-if="alert.variant === 'danger'" />
                {{ alert.message }}
              </span>
            </div>
          </b-alert>

          <!-- form -->
          <b-form
              class="auth-login-form mt-2"
              @submit.prevent="resetPassword"
          >
            <!-- password -->
            <b-form-group
                label="Senha *"
                label-for="password"
            >
              <b-input-group
                      class="input-group-merge"
                    >
                      <b-form-input
                          id="password"
                          name="password"
                          :type="tipo.nova"
                          v-model="newPasswordData.new_password"
                          @input="validate"
                          @focus="alert.show = false"
                          :class="formErrors.new_password ? 'border-danger' : ''"
                          placeholder="********"
                          autocomplete="off"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="icone.nova"
                          @click="mostraSenha('nova')"
                        />
                      </b-input-group-append>
                    </b-input-group>
              <small v-if="!validationRules && newPasswordData.new_password && !formErrors.new_password" class="text-danger"
                  >
                    A senha digitada não está de acordo com as regras.
                  <br
                /></small>
              <small class="text-danger">{{ formErrors.new_password }}</small>
            </b-form-group>

            <!-- password confirmation -->
            <b-form-group
                label="Confirme a senha *"
                label-for="password"
            >
              <b-input-group
                      class="input-group-merge"
                    >
                      <b-form-input
                          id="passwordConfirmation"
                          name="passwordConfirmation"
                          :type="tipo.confirma"
                          v-model="newPasswordData.password_confirmation"
                          @focus="alert.show = false"
                          :class="formErrors.password_confirmation ? 'border-danger' : ''"
                          placeholder="********"
                          autocomplete="off"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="icone.confirma"
                          @click="mostraSenha('confirma')"
                        />
                      </b-input-group-append>
                    </b-input-group>
              <small class="text-danger">{{ formErrors.password_confirmation }}</small>
              <small
                  v-if="newPasswordData.password_confirmation != newPasswordData.new_password"
                      class="text-danger"
                      >{{
                        "O campo confirmação de senha deve ser igual ao campo nova senha."
                      }}<br
                    /></small>
            </b-form-group>

            <!-- submit buttons -->
            <button :disabled="disabledButton" type="submit" class="btn button-form btn-block">
              {{ msgButton }}
            </button>
          </b-form>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <BIconChevronLeft />
              <span class="to-login">&nbsp;Volte ao Login</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <FooterInitial/>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BIconExclamationCircle, BIconCheckCircle, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, BIconChevronLeft,
} from 'bootstrap-vue';
import { CheckCircleIcon, SlashIcon } from 'vue-feather-icons'
import { validation } from '@core/mixins/validation/validation'
import { messages } from "@core/mixins/validation/messages";
import LoginImage from "@/views/pages/authentication/components/LoginImage";
import InitialHeader from '@/views/components/custom/page-header/InitialHeader.vue';
import FooterInitial from '@/views/components/custom/footer/FooterInitial.vue';
import { setEmailForgotPassword } from '@core/utils/store/setStore';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  title: 'Nova senha',
  
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BIconExclamationCircle,
    BIconCheckCircle,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BIconChevronLeft,
    SlashIcon,
    CheckCircleIcon,
    LoginImage,
    InitialHeader,
    FooterInitial
},

  mixins: [validation, messages],

  props: ['codigo'],

  mounted() {
    if(this.$route.params.successEmail) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'E-mail confirmado!',
          icon: 'MailIcon',
          text: 'Confirmamos o seu e-mail, agora defina sua senha para poder ter acesso a plataforma.',
          variant: 'success',
        },
      },
      {
        timeout: 8000,
      })
    }

    this.$helpers.indexablePage();
  },

  data() {
    return {
      newPasswordData: {
        code: this.codigo,
        new_password: '',
        password_confirmation: '',
      },
      loginStyle:{
          'background-image': `url(${require('@/assets/custom-images/bg/bg-login.png')})`,
          'background-repeat': 'no-repeat',
          'background-position': 'center center',
          'background-size': 'cover'
        },

      loadingShow: true,

      disabledButton: false,
      msgButton: 'Salvar',

      formErrors: {},

      alert: {
        show: false,
        message: '',
        variant: ''
      },
      icone: {
        nova: "EyeOffIcon",
        confirma: "EyeOffIcon",
      },
      tipo: {
        nova: "password",
        confirma: "password",
      },
      validationRules: true,
    }
  },

  methods: {
    mostraSenha(campo) {
      if (campo == "nova") {
        this.tipo.nova = this.tipo.nova === "password" ? "text" : "password";
        this.icone.nova =
          this.tipo.nova === "password" ? "EyeOffIcon" : "EyeIcon";
      }
      if (campo == "confirma") {
        this.tipo.confirma =
          this.tipo.confirma === "password" ? "text" : "password";
        this.icone.confirma =
          this.tipo.confirma === "password" ? "EyeOffIcon" : "EyeIcon";
      }
    },
    async resetPassword() {
      if(this.validation()) {
        this.buttonLoading(true);

        await this.$http.put(this.$api.recuperarSenha(), this.newPasswordData)
            .then((response) => {
              const status = response.request.status;

              if(status === 200) {
                this.clear();

                this.modalSuccess();
              }
            })
            .catch((error) => {
              this.errorHandling(error.response);
            })

        this.buttonLoading(false);
      }
    },

    errorHandling(response) {
      const status = response.status;
      const message = response.data.errors || this.impossibleMsg;

      switch (status) {
        case 404 :
          this.showAlert(
              message,
              'danger'
          );
          break;

        case 422 :
          this.showAlert(
              this.impossibleMsg,
              'danger'
          );
        break;
      }
    },
    validate(){
        this.validationRules = this.passwordValidate(this.newPasswordData.new_password);
      if(typeof this.validationRules == typeof undefined){
          this.validationRules = true;
      }
    },
    validation() {
      this.passwordValidate(this.newPasswordData.new_password);

      this.passwordConfirmation(this.newPasswordData.new_password, this.newPasswordData.password_confirmation);

      // Verifica se o formulário está preenchido ou não
      if(this.formValidationRun()) {
        return true;
      } else {
        this.formErrors = {};
        this.formErrors = this.formValidationErrors;

        return false;
      }
    },

    showAlert(message, variant) {
      this.alert.show = true;
      this.alert.message = message;
      this.alert.variant = variant;
    },

    modalSuccess() {
      this.$swal({
        title: 'Senha alterada com sucesso.',
        text: 'Volte à página de login e tente acessar novamente com sua nova senha.',
        icon: 'success',
        confirmButtonText: 'Ok',
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push({ name:'auth-login' });
        }
      });
    },

    clear() {
      setEmailForgotPassword(null);

      this.newPasswordData = {
        code: '',
        new_password: '',
        password_confirmation: ''
      };

      this.formErrors = {};
    },

    buttonLoading(disabled) {
      if(disabled) {
        this.disabledButton = true;
        this.msgButton = 'Processando...';
      } else {
        this.disabledButton = false;
        this.msgButton = 'Entrar';
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>